import React from "react";
import PropTypes from "prop-types";

const ContextStep = ({ text, children , isActive}) => {
  return     <div className={`context-step ${isActive ? "active" : ""}`}>
  {isActive && children}
</div>
};

ContextStep.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  isActive: PropTypes.bool
};

export default ContextStep;