import React, { useState, useEffect, useCallback } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifMessage,
  MotifSelect,
  MotifOption,
  MotifToggleSwitch,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import "./AddUser.scss";
import PropTypes from "prop-types";
import { FETCH_AD_USERS } from "../../../services/Api";
import MultiPeoplePicker from "../../peoplePicker/MultiPeoplePicker";
import { useMsal } from "@azure/msal-react";
import { MODULE_TYPES } from "../../../constants/moduleTypes/moduleTypes";
import KbaInfoIcon from "../../KbaInfoIcon/KbaInfoIcon";
import { useSelector } from "react-redux";
import { KBA_IDS } from "../../../constants/KBAIds/KBAIds";

const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = regex.exec(document.cookie);
  return match ? match[2] : null;
};

const AddUser = ({
  isOpen,
  onClose,
  page,
  TitleUserManagementEN,
  UserNameEN,
  selectedUserId,
  RoleEN,
  SelectRoleEN,
  UserEmailEN,
  CancelButtonEN,
  DoneButtonEN,
  UserManagementPageEN,
  AddModulePopupEN,
  EditModulePopupEN,
  CloneModulePopupEN,
  NewModuleTitleEN,
  SessionNameEN,
  AddSessionPopupEN,
  EditSessionPopupEN,
  SelectedsessionName,
  SelectedsessionFramework,
  setModeUpdated,
  existingPhaseId,
  existingIsVoluntary,
  existingIsEYSpecific,
  ModuleEN,
  SelectModuleEN,
  NewSessionTitleEN,
  SessionNameContentEN,
  SessionFrameworkEN,
  SelectSessionFrameworkEN,
  isDisabled,
  selectedRole,
  sessionId,
  moduleId,
  projectId,
  callApi,
  TitleUpdateUserManagementEN,
  UpdateUserManagementPageEN,
  selectedUsername,
  selectedEmail,
  UpdateButtonEN,
  ConfirmationPopup,
  RetireSessionPopup,
  RetireModulePopup,
  ConfirmationTitle,
  ConfirmationEN,
  ConfirmationDeleteMsgEN,
  userRoles,
  clientReadyLabel,
  nameLabel,
  enterNamePlaceholder,
  clientReadyPlaceholder,
  yesLabel,
  noLabel,
  moduleTypes = [],
  setModes = [],
  phases = [],
  moduleNameUpdated,
  isClientReadyUpdated,
  moduleTypeId,
  moduleIdToUpdateApi,
  handleDeleteSession,
  DeleteButton,
  PreparerLabel,
  ApproverLabel,
  approverValue,
  sessionFrameWorkArray,
  SetModeLabel,
  SelectSetModeLabel,
  ImportDMALabel,
  SelectDMAForImportingLabel,
  phaseInLabel,
  selectPhaseInLabel,
  voluntaryLabel,
  EYSpecificisLabel,
}) => {
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState(selectedEmail || "");
  const [isApprover, setIsApprover] = useState(approverValue || false);
  const [volunatary, setVolunatary] = useState(existingIsVoluntary || false);
  const [eySpecific, setEySpecific] = useState(existingIsEYSpecific || false);
  const [userFramework, setUserFramework] = useState(selectedRole || "");
  const [module, setModule] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionFramework, setSessionFramework] = useState("");
  const { instance } = useMsal();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [clientReady, setClientReady] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [sessionNameValidation, setSessionNameValidation] = useState(null);
  const [sessionFrameWorkValidation, setSessionFrameWorkValidation] =
    useState(null);
  const [moduleValidation, setModuleValidation] = useState(null);
  const [moduleNameValidation, setModuleNameValidation] = useState(null);
  const [isClientReadyValidation, setIsClientReadyValidation] = useState(null);

  const [importDma, setImportDma] = useState("");
  const [setMode, setSetMode] = useState("0");
  const [phase, setPhase] = useState("0");
  const [setModeValidation, setSetModeValidation] = useState(null);

  useEffect(() => {
    if (isOpen && page === AddSessionPopupEN) {
      setSessionNameValidation(null);
      setSessionFrameWorkValidation(null);
    } else if (isOpen && page === AddModulePopupEN) {
      setModuleValidation(null);
      setModuleNameValidation(null);
      setIsClientReadyValidation(null);
    }
  }, [
    isOpen,
    page,
    AddSessionPopupEN,
    AddModulePopupEN,
    setSessionNameValidation,
    setSessionFrameWorkValidation,
    setModuleValidation,
    setModuleNameValidation,
    setIsClientReadyValidation,
  ]);

  useEffect(() => {
    if (isOpen && page === UpdateUserManagementPageEN && selectedEmail) {
      setSelectedUsers([
        { id: selectedUserId, label: selectedEmail, value: selectedUsername },
      ]);
    }
    setUserName(selectedUsername);
    setEmail(selectedEmail);
    setIsApprover(approverValue);
    setUserFramework(selectedRole ? selectedRole.toString() : "");
  }, [
    selectedUsername,
    selectedEmail,
    UpdateUserManagementPageEN,
    selectedUserId,
    page,
    approverValue,
    isOpen,
    selectedRole,
  ]);

  useEffect(() => {
    if (page === EditSessionPopupEN && SelectedsessionName) {
      setSessionName(SelectedsessionName);
      setSessionFramework(SelectedsessionFramework.toString());
    }

    if (page === EditModulePopupEN) {
      setModuleName(moduleNameUpdated);
      setClientReady(isClientReadyUpdated);
      setModule(moduleTypeId.toString());
      setSetMode(setModeUpdated ? setModeUpdated.toString() : "0");
      setPhase(existingPhaseId ? existingPhaseId.toString() : "0");
      setVolunatary(existingIsVoluntary || false);
      setEySpecific(existingIsEYSpecific || false);
    }
  }, [
    page,
    SelectedsessionName,
    SelectedsessionFramework,
    EditSessionPopupEN,
    EditModulePopupEN,
    moduleNameUpdated,
    isClientReadyUpdated,
    moduleTypeId,
    setModeUpdated,
    existingPhaseId,
    existingIsVoluntary,
    existingIsEYSpecific,
  ]);
  useEffect(() => {
    if (!isOpen) {
      setUserName("");
      setEmail("");
      setUserFramework("");
      setModule("");
      setSessionName("");
      setSessionFramework("");
      setClientReady("");
      setModuleName("");
      setIsApprover(false);
      setVolunatary(false);
      setEySpecific(false);
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const selectedModuleType = moduleTypes.find(
    (item) => item.id.toString() === module
  );

  const specialCharPattern = /[^a-zA-Z0-9 ]/;
  const handleKeyPress = (event) => {
    if (specialCharPattern.test(event.key)) {
      event.preventDefault();
    }
  };
  const validateFieldsAddSession = () => {
    let isValid = true;
    const specialCharPattern = /[^a-zA-Z0-9 ]/;
    if (!sessionName) {
      setSessionNameValidation("Session name is required");
      isValid = false;
    } else if (specialCharPattern.test(module)) {
      setSessionNameValidation(
        "Session name should not contain special characters"
      );
      isValid = false;
    } else {
      setSessionNameValidation("");
    }
    if (!sessionFramework) {
      setSessionFrameWorkValidation("Session framework is required");
      isValid = false;
    } else {
      setSessionFrameWorkValidation("");
    }
    return isValid;
  };
  const validateFieldsAddModule = () => {
    let isValid = true;
    const specialCharPattern = /[^a-zA-Z0-9 ]/;

    if (!moduleName) {
      setModuleNameValidation("Module name is required.");
      isValid = false;
    } else if (specialCharPattern.test(moduleName)) {
      setModuleNameValidation("Module name contains invalid characters.");
      isValid = false;
    } else {
      setModuleNameValidation(null);
    }

    if (!module) {
      setModuleValidation("Module type is required.");
      isValid = false;
    } else {
      setModuleValidation(null);
    }

    if (!clientReady) {
      setIsClientReadyValidation("Client ready status is required.");
      isValid = false;
    } else {
      setIsClientReadyValidation(null);
    }

    return isValid;
  };
  const handleDone = () => {
    switch (page) {
      case AddSessionPopupEN:
        if (validateFieldsAddSession()) {
          callApi({
            name: sessionName,
            frameworkId: parseInt(sessionFramework, 10),
          });
        }
        break;
      case EditSessionPopupEN:
        callApi({
          name: sessionName,
          id: sessionId,
        });
        break;
      case AddModulePopupEN:
        if (validateFieldsAddModule()) {
          callApi({
            projectId: projectId,
            moduleTypeId: parseInt(module, 10),
            sessionId: parseInt(sessionId, 10),
            name: moduleName,
            isClientReady: clientReady === "Yes",
          });
        }

        break;
      case EditModulePopupEN:
        if (
          selectedModuleType &&
          selectedModuleType.name === MODULE_TYPES.ESRS_CHECKLIST &&
          (setMode === "0" || !setMode)
        ) {
          setSetModeValidation("Set mode is required");
          return;
        } else {
          setSetModeValidation(null);
        }
        callApi({
          moduleId: moduleIdToUpdateApi,
          isClientReady: clientReady === "Yes",
          name: moduleName,
          moduleAssessmentTypeId: parseInt(setMode, 10),
          phaseInFilterId: parseInt(phase, 10),
          isVoluntary: volunatary,
          isEYSpecific: eySpecific,
        });
        break;
      case UserManagementPageEN:
        callApi({
          aadId: selectedUsers[0].id,
          name: selectedUsers[0].value,
          email: selectedUsers[0].label,
          projectRoleId: parseInt(userFramework, 10),
          isApprover,
        });
        break;
      case UpdateUserManagementPageEN:
        callApi({
          aadId: selectedUsers.id,
          projectRoleId: parseInt(userFramework, 10),
          isApprover,
        });
        break;
      case RetireSessionPopup:
        callApi({ id: sessionId });
        break;
      case RetireModulePopup:
        callApi({ id: moduleId });
        break;
      case CloneModulePopupEN:
        /* --- API Call when api is ready */
        break;

      default:
        callApi();
    }
  };
  const searchUsers = useCallback(async (searchQuery, accessToken) => {
    try {
      const filterQuery = encodeURIComponent(
        `startswith(displayName,'${searchQuery}') or startswith(mail,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')`
      );
      const response = await fetch(`${FETCH_AD_USERS}?$filter=${filterQuery}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching users: ${response.statusText}`);
      }

      const data = await response.json();
      return data.value;
    } catch (error) {
      console.error("Error in searchUsers:", error);
      return [];
    }
  }, []);

  const getADUsers = useCallback(
    async (inputValue) => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["User.ReadBasic.All"],
            account: accounts[0],
          });
          const accessToken = tokenResponse.accessToken;
          const users = await searchUsers(inputValue, accessToken);
          return users.map((user) => ({
            id: user.id,
            displayName: user.displayName,
            emailId: user.mail,
          }));
        } catch (error) {
          console.error("Error fetching AD users:", error);
          return [];
        }
      } else if (getCookie("accessToken")) {
        const users = await searchUsers(inputValue, getCookie("accessToken"));
        return users.map((user) => ({
          id: user.id,
          displayName: user.displayName,
          emailId: user.mail,
        }));
      }
      return [];
    },
    [instance, searchUsers]
  );

  const handleChange = useCallback((selectedList) => {
    setSelectedUsers(selectedList);
  }, []);

  const handleFrameworkChange = (selectedFrameworkId) => {
    setUserFramework(selectedFrameworkId);
    const selectedRole = userRoles.find(
      (role) => role.id === selectedFrameworkId
    );
    if (selectedRole && selectedRole.name === "Project guest") {
      setIsApprover(false);
    }
  };

  const changeApproverToggle = () => {
    const selectedRole = userRoles.find(
      (role) => role.id === parseInt(userFramework, 10)
    );
    if (selectedRole && selectedRole.name !== "Project guest") {
      setIsApprover(!isApprover);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
        className={`modal ${
          page === CloneModulePopupEN ? "clone-module-modal" : ""
        }`}
      >
        <div className="modal-header">
          <h2>
            {page === UserManagementPageEN && TitleUserManagementEN}
            {page === AddSessionPopupEN && NewSessionTitleEN}
            {page === AddModulePopupEN && NewModuleTitleEN}
            {page === EditModulePopupEN && NewModuleTitleEN}
            {page === CloneModulePopupEN && NewModuleTitleEN}
            {page === UpdateUserManagementPageEN && TitleUpdateUserManagementEN}
            {page === EditSessionPopupEN && NewSessionTitleEN}
            {(page === ConfirmationPopup ||
              page === RetireSessionPopup ||
              page === RetireModulePopup) &&
              ConfirmationTitle}
          </h2>
        </div>
        <div className="modal-body">
          {page === UserManagementPageEN && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">{UserEmailEN}*</MotifMessage>
                <MotifFormField>
                  <MultiPeoplePicker
                    getADUsers={getADUsers}
                    onChange={(selectedList) => {
                      handleChange(selectedList);
                    }}
                    activeDirectoryData={selectedUsers}
                    options={[]}
                    disabled={false}
                    isMandatory={true}
                    label="Users"
                    value={selectedUsers}
                    isErrorVisible={false}
                    errorMessage=""
                    isErrorVisible2={false}
                    errorMessage2=""
                    pageName={"User Management"}
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page === UpdateUserManagementPageEN && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">{UserEmailEN}</MotifMessage>
                <MotifFormField>
                  <MultiPeoplePicker
                    getADUsers={getADUsers}
                    onChange={(selectedList) => {
                      handleChange(selectedList);
                    }}
                    activeDirectoryData={selectedUsers}
                    options={[]}
                    disabled={true}
                    label="Users"
                    value={selectedUsers}
                    isErrorVisible={false}
                    errorMessage=""
                    isErrorVisible2={false}
                    pageName={"User Management"}
                    disabledPicker={true}
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page !== ConfirmationPopup &&
            page !== RetireSessionPopup &&
            page !== RetireModulePopup && (
              <div
                className={`form-row ${
                  page === CloneModulePopupEN ? "clone-module-form-row" : ""
                }`}
              >
                <div className="form-group">
                  <MotifMessage id="form-title">
                    {(page === UserManagementPageEN ||
                      page === UpdateUserManagementPageEN) &&
                      UserNameEN}
                    {(page === AddModulePopupEN ||
                      page === AddSessionPopupEN ||
                      page === EditModulePopupEN ||
                      page === EditSessionPopupEN) &&
                      SessionNameEN}
                  </MotifMessage>
                  {page === UserManagementPageEN && (
                    <MotifFormField>
                      <MotifInput
                        className="user-modal-input"
                        value={selectedUsers && selectedUsers.length > 0 ? selectedUsers[0].value : ""}
                        onChange={(e) => setUserName(e.target.value)}
                        aria-describedby="user-name"
                        hideClearButton={true}
                        disabled={true}
                        data-testid="username"
                      />
                    </MotifFormField>
                  )}
                  {page === UpdateUserManagementPageEN && (
                    <MotifFormField>
                      <MotifInput
                        className="user-modal-input"
                        value={selectedUsername}
                        onChange={(e) => setUserName(e.target.value)}
                        aria-describedby="user-name"
                        hideClearButton={true}
                        data-testid="username"
                        disabled={true}
                      />
                    </MotifFormField>
                  )}
                  {(page === AddModulePopupEN ||
                    page === EditModulePopupEN) && (
                    <MotifFormField>
                      <MotifInput
                        className="session-modal-input session-name-input"
                        value={SelectedsessionName}
                        aria-describedby="session-name"
                        hideClearButton={true}
                        disabled={true}
                      />
                    </MotifFormField>
                  )}
                  {(page === AddModulePopupEN ||
                    page === EditModulePopupEN ||
                    page === CloneModulePopupEN) && (
                    <div>
                      <MotifMessage id="form-title">{nameLabel}*</MotifMessage>
                      <MotifFormField>
                        <MotifLabel
                          aria-label="Input"
                          for="input"
                          position="in"
                        >
                          {enterNamePlaceholder}
                        </MotifLabel>
                        <MotifInput
                          className="module-modal-input"
                          labelPosition="in"
                          value={moduleName}
                          onChange={(e) => setModuleName(e.target.value)}
                          onKeyPress={handleKeyPress}
                          aria-describedby="module-name"
                          hideClearButton={true}
                        />
                        {moduleNameValidation && (
                          <MotifErrorMessage>
                            {moduleNameValidation}
                          </MotifErrorMessage>
                        )}
                      </MotifFormField>
                    </div>
                  )}
                  {(page === AddSessionPopupEN ||
                    page === EditSessionPopupEN) && (
                    <MotifFormField>
                      <MotifLabel
                        aria-label="session-name"
                        for="input"
                        position="in"
                      >
                        {SessionNameContentEN}
                      </MotifLabel>
                      <MotifInput
                        className="session-modal-input session-name-input"
                        labelPosition="in"
                        onKeyPress={handleKeyPress}
                        aria-describedby="session-name"
                        hideClearButton={true}
                        onChange={(e) => setSessionName(e.target.value)}
                        value={sessionName}
                      />
                      {sessionNameValidation && (
                        <MotifErrorMessage>
                          {sessionNameValidation}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                  )}
                </div>
                {(page === AddSessionPopupEN ||
                  page === EditSessionPopupEN) && (
                  <div className="form-group">
                    <MotifMessage id="session-framework">
                      {SessionFrameworkEN}
                    </MotifMessage>
                    <MotifFormField>
                      <MotifLabel id="select-session-label" position="in">
                        {SelectSessionFrameworkEN}
                      </MotifLabel>
                      <MotifSelect
                        className="session-modal-input"
                        ariaLabelledBy="select-session-label"
                        onChange={(e) => setSessionFramework(e)}
                        labelPosition="in"
                        value={sessionFramework}
                        disabled={page === EditSessionPopupEN}
                      >
                        {sessionFrameWorkArray.map((item) => (
                          <MotifOption key={item.id} value={item.id.toString()}>
                            {item.group}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                      {sessionFrameWorkValidation && (
                        <MotifErrorMessage>
                          {sessionFrameWorkValidation}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                  </div>
                )}
                {page === UserManagementPageEN && (
                  <div className="form-group">
                    <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                    <MotifFormField>
                      <MotifLabel
                        aria-label="select-user-role-label"
                        position="in"
                      >
                        {SelectRoleEN}
                      </MotifLabel>
                      <MotifSelect
                        className="user-modal-input"
                        value={userFramework}
                        labelPosition="in"
                        ariaLabelledBy="select-user-role-label"
                        onChange={(e) => handleFrameworkChange(e)}
                        visibleOptions={2}
                        data-testid="userframework"
                      >
                        {userRoles.map((role) => (
                          <MotifOption key={role.id} value={role.id.toString()}>
                            {role.name}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                    </MotifFormField>
                  </div>
                )}
                {page === UserManagementPageEN && (
                  <div className="form-group half-width">
                    <MotifFormField className="human-rights-toggle">
                      <MotifToggleSwitch
                        onChange={changeApproverToggle}
                        offLabel={PreparerLabel}
                        onLabel={ApproverLabel}
                        id="exampleToggle"
                        hideLabels={false}
                        checked={isApprover}
                      />
                    </MotifFormField>
                  </div>
                )}
                {page === UpdateUserManagementPageEN && (
                  <div className="form-group">
                    <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                    <MotifFormField>
                      <MotifLabel
                        id="select-user-update-role-label"
                        position="in"
                      >
                        {SelectRoleEN}
                      </MotifLabel>
                      <MotifSelect
                        className="user-modal-input"
                        labelPosition="in"
                        ariaLabelledBy="select-user-update-role-label"
                        visibleOptions={2}
                        data-testid="userframework"
                        onChange={(e) => handleFrameworkChange(e)}
                        value={userFramework}
                      >
                        {userRoles.map((role) => (
                          <MotifOption key={role.id} value={role.id.toString()}>
                            {role.name}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                    </MotifFormField>
                  </div>
                )}
                {page === UpdateUserManagementPageEN && (
                  <div className="form-group half-width">
                    <MotifFormField className="human-rights-toggle">
                      <MotifToggleSwitch
                        onChange={changeApproverToggle}
                        offLabel={PreparerLabel}
                        onLabel={ApproverLabel}
                        id="exampleToggle"
                        hideLabels={false}
                        checked={isApprover}
                      />
                    </MotifFormField>
                  </div>
                )}

                {(page === AddModulePopupEN || page === EditModulePopupEN) && (
                  <div className="form-group">
                    <MotifMessage id="module-framework">
                      {ModuleEN}
                    </MotifMessage>
                    <MotifFormField>
                      <MotifLabel id="select-module-label" position="in">
                        {SelectModuleEN}
                      </MotifLabel>
                      <MotifSelect
                        className="module-modal-input"
                        ariaLabelledBy="select-module-label"
                        onChange={(e) => setModule(e)}
                        value={module}
                        visibleOptions={3}
                        disabled={page === EditModulePopupEN}
                        labelPosition="in"
                      >
                        {moduleTypes.map((module) => (
                          <MotifOption
                            key={module.id}
                            value={module.id.toString()}
                            title={module.description}
                          >
                            {`${module.name} - ${module.variant}`}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                      {moduleValidation && (
                        <MotifErrorMessage>
                          {moduleValidation}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                    <div>
                      <div className="row-label-with-icon">
                        <MotifMessage id="module-framework">
                          {clientReadyLabel}
                        </MotifMessage>
                        <KbaInfoIcon
                          kbaId={KBA_IDS.CLIENT_READY}
                          language={sessionStorage.getItem("language") || "EN"}
                          token={token}
                          iconStyle={{}}
                        />
                      </div>

                      <MotifFormField>
                        <MotifLabel
                          id="select-client-ready-label"
                          position="in"
                        >
                          {clientReadyPlaceholder}
                        </MotifLabel>
                        <MotifSelect
                          className="module-modal-select"
                          ariaLabelledBy="select-client-ready-label"
                          labelPosition="in"
                          onChange={(e) => setClientReady(e)}
                          value={clientReady}
                        >
                          <MotifOption value="Yes">{yesLabel}</MotifOption>
                          <MotifOption value="No">{noLabel}</MotifOption>
                        </MotifSelect>
                        {isClientReadyValidation && (
                          <MotifErrorMessage>
                            {isClientReadyValidation}
                          </MotifErrorMessage>
                        )}
                      </MotifFormField>
                    </div>
                  </div>
                )}

                {page === EditModulePopupEN &&
                  selectedModuleType &&
                  (selectedModuleType?.name === MODULE_TYPES.DGA ||
                    selectedModuleType?.name ===
                      MODULE_TYPES.ESRS_CHECKLIST) && (
                    <div className="form-group dma-import-group">
                      <MotifMessage id="import-dma-label">
                        {ImportDMALabel}
                      </MotifMessage>
                      <div className="dma-import-container">
                        <MotifFormField className="dma-import-dropdown">
                          <MotifLabel
                            id="import-dma-select-label"
                            position="in"
                          >
                            {SelectDMAForImportingLabel}
                          </MotifLabel>
                          <MotifSelect
                            className="module-modal-select"
                            ariaLabelledBy="import-dma-select-label"
                            labelPosition="in"
                            onChange={(e) => setImportDma(e)}
                            value={importDma}
                          >
                            {/* Populate options dynamically here */}
                          </MotifSelect>
                        </MotifFormField>
                        <MotifButton
                          className="import-button"
                          onClick={() => console.log("Import DMA clicked")}
                        >
                          Import
                        </MotifButton>
                      </div>
                    </div>
                  )}

                {page === EditModulePopupEN &&
                  selectedModuleType &&
                  selectedModuleType?.name === MODULE_TYPES.ESRS_CHECKLIST && (
                    <>
                      <div className="form-group">
                        <MotifMessage id="set-mode-label">
                          {SetModeLabel}
                        </MotifMessage>
                        <MotifFormField>
                          <MotifLabel id="set-mode-select-label" position="in">
                            {SelectSetModeLabel}
                          </MotifLabel>
                          <MotifSelect
                            className="module-modal-select"
                            ariaLabelledBy="set-mode-select-label"
                            labelPosition="in"
                            onChange={(e) => setSetMode(e)}
                            value={setMode}
                          >
                            {setModes.map((mode) => (
                              <MotifOption
                                key={mode.id}
                                value={mode.id.toString()}
                              >
                                {`${mode.name}`}
                              </MotifOption>
                            ))}
                          </MotifSelect>
                          {setModeValidation && (
                            <MotifErrorMessage>
                              {setModeValidation}
                            </MotifErrorMessage>
                          )}
                        </MotifFormField>
                      </div>
                      <div className="form-group">
                        <MotifMessage id="filter-label">
                          {phaseInLabel}
                        </MotifMessage>
                        <MotifFormField>
                          <MotifLabel
                            id="set-filter-select-label"
                            position="in"
                          >
                            {selectPhaseInLabel}
                          </MotifLabel>
                          <MotifSelect
                            className="module-modal-select"
                            ariaLabelledBy="set-filter-select-label"
                            labelPosition="in"
                            onChange={(e) => setPhase(e)}
                            value={phase}
                          >
                            {phases.map((phaseItem) => (
                              <MotifOption
                                key={phaseItem.id}
                                value={phaseItem.id.toString()}
                              >
                                {`${phaseItem.name}`}
                              </MotifOption>
                            ))}
                          </MotifSelect>
                        </MotifFormField>
                      </div>
                      <div className="form-row toggles-inline">
                        <div className="form-group">
                          <MotifMessage data-testid="human-rights">
                            {voluntaryLabel}
                          </MotifMessage>
                          <MotifFormField className="human-rights-toggle">
                            <MotifToggleSwitch
                              onChange={() => {
                                setVolunatary(!volunatary);
                              }}
                              id="voluntaryToggle"
                              hideLabels={true}
                              checked={volunatary}
                            />
                          </MotifFormField>
                        </div>
                        <div className="form-group">
                          <MotifMessage data-testid="human-rights">
                            {EYSpecificisLabel}
                          </MotifMessage>
                          <MotifFormField className="human-rights-toggle">
                            <MotifToggleSwitch
                              onChange={() => {
                                setEySpecific(!eySpecific);
                              }}
                              id="eySpecificToggle"
                              hideLabels={true}
                              checked={eySpecific}
                            />
                          </MotifFormField>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}

          {(page === ConfirmationPopup ||
            page === RetireSessionPopup ||
            page === RetireModulePopup) && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">
                  {ConfirmationDeleteMsgEN}
                </MotifMessage>
              </div>
            </div>
          )}
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {(page === EditSessionPopupEN || page === EditModulePopupEN) && (
              <MotifButton
                className="modal-delete-button"
                onClick={handleDeleteSession}
              >
                {DeleteButton}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {CancelButtonEN}
            </MotifButton>
            <MotifButton
              className="modal-done-button"
              disabled={isDisabled}
              onClick={handleDone}
            >
              {page === UpdateUserManagementPageEN
                ? UpdateButtonEN
                : DoneButtonEN}
              {(page === ConfirmationPopup ||
                page === RetireSessionPopup ||
                page === RetireModulePopup) &&
                ConfirmationEN}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

AddUser.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string,
  TitleUserManagementEN: PropTypes.string,
  UserNameEN: PropTypes.string,
  RoleEN: PropTypes.string,
  selectedRole: PropTypes.string,
  SelectRoleEN: PropTypes.string,
  UserEmailEN: PropTypes.string,
  CancelButtonEN: PropTypes.string,
  DoneButtonEN: PropTypes.string,
  UserManagementPageEN: PropTypes.string,
  AddModulePopupEN: PropTypes.string,
  EditModulePopupEN: PropTypes.string,
  CloneModulePopupEN: PropTypes.string,
  NewModuleTitleEN: PropTypes.string,
  SessionNameEN: PropTypes.string,
  AddSessionPopupEN: PropTypes.string,
  EditSessionPopupEN: PropTypes.string,
  SelectedsessionName: PropTypes.string,
  SelectedsessionFramework: PropTypes.number,
  setModeUpdated: PropTypes.number,
  existingPhaseId: PropTypes.number,
  existingIsVoluntary: PropTypes.bool,
  existingIsEYSpecific: PropTypes.bool,
  ModuleEN: PropTypes.string,
  SelectModuleEN: PropTypes.string,
  NewSessionTitleEN: PropTypes.string,
  SessionNameContentEN: PropTypes.string,
  SessionFrameworkEN: PropTypes.string,
  SelectSessionFrameworkEN: PropTypes.string,
  ESRSEN: PropTypes.string,
  sessionId: PropTypes.string,
  moduleId: PropTypes.string,
  callApi: PropTypes.func,
  TitleUpdateUserManagementEN: PropTypes.string,
  UpdateUserManagementPageEN: PropTypes.string,
  selectedUsername: PropTypes.string,
  selectedEmail: PropTypes.string,
  UpdateButtonEN: PropTypes.string,
  ConfirmationPopup: PropTypes.string,
  RetireSessionPopup: PropTypes.string,
  RetireModulePopup: PropTypes.string,
  ConfirmationTitle: PropTypes.string,
  ConfirmationEN: PropTypes.string,
  ConfirmationDeleteMsgEN: PropTypes.string,
  PreparerLabel: PropTypes.string,
  ApproverLabel: PropTypes.string,
  userRoles: PropTypes.array,
  clientReadyLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  enterNamePlaceholder: PropTypes.string,
  clientReadyPlaceholder: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  moduleTypes: PropTypes.array,
  setModes: PropTypes.array,
  phases: PropTypes.array,
  moduleNameUpdated: PropTypes.string,
  isClientReadyUpdated: PropTypes.string,
  moduleTypeId: PropTypes.string,
  moduleIdToUpdateApi: PropTypes.string,
  handleDeleteSession: PropTypes.func,
  DeleteButton: PropTypes.string,
  selectedUserId: PropTypes.string,
  approverValue: PropTypes.bool,
  projectId: PropTypes.number,
  sessionFrameWorkArray: PropTypes.array,
  SelectDMAForImportingLabel: PropTypes.string,
  SetModeLabel: PropTypes.string,
  SelectSetModeLabel: PropTypes.string,
  ImportDMALabel: PropTypes.string,
  phaseInLabel: PropTypes.string,
  selectPhaseInLabel: PropTypes.string,
  voluntaryLabel: PropTypes.string,
  EYSpecificisLabel: PropTypes.string,
};

export default AddUser;
