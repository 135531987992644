import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifErrorMessage,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "../Modal.scss";

const ClientModal = ({
  onClose,
  setName,
  handleSourceValueClick,
  modalMode,
  nameLabel,
  typeLabel,
  emailLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  InputName,
  SelectType,
  types,
  isDisbaled,
  selectedRow,
  EmailInput,
  pageName,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [typeId, setTypeId] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameInputError, setNameInputError] = useState("");
  const [typeIdError, setTypeIdError] = useState("");

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.name);
      setTypeId(selectedRow.clientOrganisationType.id.toString());
      setEmail(selectedRow.email);
    }
  }, [modalMode, selectedRow, pageName]);

  const isEditMode = modalMode === "edit";

  const handleSaveClick = () => {
    let isValid = true;
    if (!nameInput) {
      setNameInputError("Name is required.");
      isValid = false;
    } else {
      setNameInputError("");
    }

    if (!typeId) {
      setTypeIdError("Type is required.");
      isValid = false;
    } else {
      setTypeIdError("");
    }

    if (email && !validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!isValid) return;

    const sourcesData = {
      name: nameInput,
      email,
      typeId,
    };
    handleSourceValueClick(sourcesData);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const specialCharPattern = /[!#$%^&*(),?":{}|<>]/g;
    return emailRegex.test(email) && !specialCharPattern.test(email);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="form-title"> {nameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  className="session-modal-input session-name-input"
                  labelPosition="in"
                  aria-describedby="select-dma-name-input-label"
                  hideClearButton={true}
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
              </MotifFormField>
              {nameInputError && (
                <MotifErrorMessage>{nameInputError}</MotifErrorMessage>
              )}
            </div>
            <div className="form-group">
              <MotifMessage id="form-title"> {typeLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-dma-select-label" position="in">
                  {SelectType}
                </MotifLabel>
                <MotifSelect
                  className="session-modal-input"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  onChange={(e) => setTypeId(e)}
                  value={typeId}
                >
                  {types?.map((type) => (
                    <MotifOption key={type.id} value={type.id.toString()}>
                      {type.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
              {typeIdError && (
                <MotifErrorMessage>{typeIdError}</MotifErrorMessage>
              )}
            </div>
          </div>
          <MotifMessage id="form-title"> {emailLabel}</MotifMessage>
          <MotifFormField>
            <MotifLabel
              id="select-dma-name-input-label"
              position="in"
              htmlFor="name-input"
            >
              {EmailInput}
            </MotifLabel>
            <MotifInput
              className="session-modal-input session-name-input"
              labelPosition="in"
              aria-describedby="select-dma-name-input-label"
              hideClearButton={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-testid="username"
            />
          </MotifFormField>
          {emailError && <MotifErrorMessage>{emailError}</MotifErrorMessage>}
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="modal-cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            onClick={handleSaveClick}
            disabled={isDisbaled}
            className="modal-done-button"
          >
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

ClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  InputName: PropTypes.string.isRequired,
  SelectType: PropTypes.string.isRequired,
  EmailInput: PropTypes.string,
  isDisbaled: PropTypes.bool,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedRow: PropTypes.object,
};

export default ClientModal;
