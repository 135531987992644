import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  Suspense,
  useCallback,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TableCell, Box, IconButton, Typography } from "@mui/material";
import CustomTooltip from "../../constants/customTooltip/CustomTooltip";
import { Edit, View } from "../../constants/icons/Icons";
import language from "../../constants/languages/en/translations.json";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import { useMsal } from "@azure/msal-react";
import {
  setUser,
  getPhoto,
  setJwtToken,
  resetLogoutFlag,
} from "../../features/slices/HomeSlice";
import { checkAdminUsers } from "../../features/slices/UsersData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../../features/slices/InstanceHome";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import "./InstanceHome.scss";
import Footer from "../../utils/footer";
import { useTranslation } from "react-i18next";

const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);
const TOKEN_REFRESH_INTERVAL = 15 * 60 * 1000;
const TOKEN_EXPIRY_TIME = 30 * 60 * 1000;

let serializedAccount = null;

const InstanceHome = ({ showHeader = true, showButton = true, marginTop }) => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const projectIDsRef = useRef([]);
  const inactivityTimeoutRef = useRef(null);
  const tokenRefreshIntervalRef = useRef(null);
  const isAdmin = true;
  const [searchQuery, setSearchQuery] = useState("");
  const [transformedData, setTransformedData] = useState([]);
  const userState = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.jwtToken);
  const hasLoggedOut = useSelector((state) => state.user.hasLoggedOut);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const marginTopStyle = {
    marginTop: marginTop || "8%",
  };

  const serializeAccount = useCallback((account) => {
    return {
      ...account,
      tenantProfiles: account.tenantProfiles
        ? Object.fromEntries(account.tenantProfiles)
        : {},
    };
  }, []);

  const expireToken = useCallback(() => {
    console.warn("Token expired due to inactivity");
    dispatch(setJwtToken(null));
    navigate("/");
  }, [dispatch, navigate]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(expireToken, TOKEN_EXPIRY_TIME);
  }, [expireToken]);

  const refreshToken = useCallback(async () => {
    if (instance && accounts.length > 0) {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["https://database.windows.net/user_impersonation"],
          account: accounts[0],
        });
        const newToken = tokenResponse.accessToken;
        dispatch(setJwtToken(newToken));
        sessionStorage.setItem("authToken", newToken);
        resetInactivityTimer();
      } catch (error) {
        console.error("Failed to refresh token", error);
        expireToken();
      }
    }
  }, [instance, accounts, dispatch, resetInactivityTimer, expireToken]);

  useEffect(() => {
    const initializeAuth = async () => {
      if (hasLoggedOut) {
        dispatch(resetLogoutFlag());
        return;
      }
      if (!instance) {
        console.error("MSAL instance is not initialized");
        return;
      }
      try {
        if (accounts.length === 0 && inProgress === "none") {
          await instance.initialize();
          await instance.loginRedirect({
            scopes: ["https://database.windows.net/user_impersonation"],
          });
        } else if (accounts.length > 0) {
          await instance.initialize();
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["https://database.windows.net/user_impersonation"],
            account: accounts[0],
          });
          const token = tokenResponse.accessToken;
          serializedAccount = serializeAccount(accounts[0]);
          dispatch(setUser(serializedAccount));
          dispatch(setJwtToken(token));
          sessionStorage.setItem("authToken", token);
          sessionStorage.setItem("user", JSON.stringify(serializedAccount));
          dispatch(getPhoto())
            .unwrap()
            .then((photo) => {
              sessionStorage.setItem("photo", photo);
            });
          dispatch(fetchAllProjects({ token }));
        }
      } catch (error) {
        console.error("Authentication failed", error);
      }
    };

    if (instance) {
      initializeAuth();
    } else {
      console.error("MSAL instance not available");
    }

    tokenRefreshIntervalRef.current = setInterval(
      refreshToken,
      TOKEN_REFRESH_INTERVAL
    );
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    resetInactivityTimer();

    return () => {
      clearInterval(tokenRefreshIntervalRef.current);
      clearTimeout(inactivityTimeoutRef.current);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, [
    instance,
    accounts,
    inProgress,
    dispatch,
    serializeAccount,
    refreshToken,
    resetInactivityTimer,
    hasLoggedOut,
  ]);

  const {
    data: responseData,
    isError,
    isLoading,
    errorMessage,
  } = useSelector(
    (state) => state.allProjects || { data: null, isLoading: false }
  );
  useEffect(() => {
    const checkAuthorization = async () => {
      if (accounts.length > 0 && token) {
        try {
          const email = accounts[0].username;
          const response = await dispatch(
            checkAdminUsers({ emailId: email, token })
          ).unwrap();
          setIsAuthorized(response.data);
        } catch (error) {
          console.error("Error checking admin authorization:", error);
          setIsAuthorized(false);
        }
      }
    };
    checkAuthorization();
  }, [accounts, token, dispatch]);

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("ProjectName"), showSorting: true },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  useEffect(() => {
    if (responseData?.data) {
      projectIDsRef.current = responseData.data.map((item) => item.id);
      const data = responseData.data.map((item, index) => {
        const transformedItem = {};
        columnsToDisplay.forEach((column) => {
          if (column.colName !== "Action") {
            transformedItem[column.colName] = item[column.colName];
          }
        });
        transformedItem.id = projectIDsRef.current[index];
        return transformedItem;
      });
      setTransformedData(data);
    }
  }, [responseData, columnsToDisplay]);

  const handleNavigate = useCallback(async () => {
    if (!isLoading) {
      const serializedAccount = serializeAccount(accounts[0]);
      if (!serializedAccount) {
        console.error("Serialized account is not available");
        return;
      }

      try {
        const email = serializedAccount.username;
        const userId = serializedAccount.localAccountId;
        const name = serializedAccount.name;

        navigate("/create-new-project", {
          state: { token, email, userId, name },
        });
      } catch (error) {
        console.error("Error navigating to create-new-project:", error);
      }
    }
  }, [navigate, token, serializeAccount, accounts, isLoading]);

  const generateRowKey = useCallback((row) => row.id, []);

  const getCellStyle = useCallback((column, index) => {
    return column === "Action"
      ? { textAlign: "right" }
      : { textAlign: index === 0 ? "left" : "right" };
  }, []);

  const handleButtonClick = useCallback(
    (projectId, projectName, buttonName) => {
      switch (buttonName) {
        case "View":
          navigate(`/project-home/${projectId}`, {
            state: { token, projectName },
          });
          break;
        case "Edit":
          navigate(`/edit-project/${projectId}`, {
            state: { token, projectName },
          });
          break;
        default:
          navigate(`/`);
          break;
      }
    },
    [navigate, token]
  );

  const renderTableCell = useCallback(
    (column, value, row, index) => {
      const cellStyle = getCellStyle(column, index);
      const cellPaddingRight = index === column?.length - 1 ? "4%" : "0";
      if (column === "Action") {
        return (
          <TableCell key="Action" sx={{ textAlign: "right", width: "150px" }}>
            <Box display="flex" justifyContent="flex-end">
              <CustomTooltip title="View project" placement="top">
                <IconButton
                  aria-label={`${row.name} view details`}
                  onClick={() => handleButtonClick(row.id, row.name, "View")}
                  data-testid="view-button"
                >
                  <View />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Edit project" placement="top">
                <IconButton
                  aria-label={`${row.name} edit details`}
                  onClick={() => handleButtonClick(row.id, row.name, "Edit")}
                >
                  <Edit />
                </IconButton>
              </CustomTooltip>
            </Box>
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={column}
            style={{ ...cellStyle, paddingRight: cellPaddingRight }}
          >
            {value}
          </TableCell>
        );
      }
    },
    [getCellStyle, handleButtonClick]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredData = useMemo(() => {
    return transformedData.filter((item) => {
      const nameValue = item["name"] || "";
      const query = searchQuery.toLowerCase();
      return nameValue.toLowerCase().includes(query);
    });
  }, [transformedData, searchQuery]);

  const handleError = useCallback(() => {
    window.location.reload();
  }, []);

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  let content;
  if (userState.user) {
    content = !isLoading && (
      <ClientTable
        columns={columnsToDisplay}
        data={filteredData}
        itemsPerPage={5}
        generateRowKey={generateRowKey}
        getCellStyle={getCellStyle}
        renderTableCell={renderTableCell}
        handleButtonClick={handleButtonClick}
      />
    );
  } else {
    content = (
      <Typography variant="body1" className="watermark">
        {t("LogOutMessage")}
      </Typography>
    );
  }

  return (
    <div className="all-client-container">
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <>
          {showHeader && <Header />}
          <div
            className="instance-home-client-org-container"
            style={marginTopStyle}
          >
            <div className="instance-home-client-org-subheader">
              {userState.user && (
                <Subheader
                  isAdmin={isAdmin}
                  page={language.AddNewAccessTitleProject}
                  title={t("Projects")}
                  handleNavigate={handleNavigate}
                  AddNewEN={t("newProject")}
                  disableProject={!isAuthorized}
                  onChangeSearchInput={handleSearch}
                  AddNewAccessTitleProject={language.AddNewAccessTitleProject}
                  showAddNewButton={showButton}
                  data-testid="add-new-project-button"
                />
              )}
            </div>
            <div>
              <Suspense
                fallback={
                  <MotifProgressLoader
                    data-testid="loading-spinner"
                    show
                    variant="default"
                  />
                }
              >
                {content}
              </Suspense>
            </div>
          </div>
          <div className="version-number">
            <Typography variant="body2" align="right">
              <Footer />
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

InstanceHome.propTypes = {
  showHeader: PropTypes.bool,
  showButton: PropTypes.bool,
  marginTop: PropTypes.string,
};

export default React.memo(InstanceHome);
